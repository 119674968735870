
import {createSlice} from "@reduxjs/toolkit";
import {tr} from "date-fns/locale";
import {IInsuranceCompany} from "store/rootstate";
interface IMasterdata{
  autocompleteOptions:{
    insuranceCompany: Array<IInsuranceCompany>
    requiredDrug: Array<any>
    icd10: Array<any>
    compendiumData: Array<any>
  },
  pubMedAbstracts:{[key:string]:string}
  requiredMigelData:any
  pubMedData:Array<any>
  breathingDevice:any
}

const initialState : IMasterdata={
  autocompleteOptions: {
    insuranceCompany: [],
    requiredDrug: [],
    icd10: [],
    compendiumData: [],
  },
  pubMedAbstracts: {},
  requiredMigelData: [],
  pubMedData: [],
  breathingDevice: {},
  // Masterdatas: {},
};
const MasterdataReducerSlices = createSlice({
  name: "MasterdataReducer",
  initialState: initialState,
  reducers: {
    onReceivedBagData(state, {payload: {data}}) {
      console.debug("onReceivedBagData data", data);
      if (data !== undefined && Array.isArray(data)) {
        data.forEach((element) => {
          console.debug("onReceivedBagData element", element);
          if (element !== undefined) {
            if (
              element.medicationHasIndicationCodes ||
              element.inSL === false ||
              element?.medicationHasLimitations === true
            ) {
              element.NEEDS_KOGU_REQUEST = true;
            } else {
              element.NEEDS_KOGU_REQUEST = false;
            }
          }
        });
      }
      return {
        ...state,
        autocompleteOptions: {
          ...state.autocompleteOptions,
          requiredDrug: data,
        },
      };
    },
    onReceivedCompendiumData(state, {payload: {data}}) {
      console.debug("onReceivedCompendiumData", data);
      return {
        ...state,
        autocompleteOptions: {
          ...state.autocompleteOptions,
          compendiumData: data,
        },
      };
    },

    onReceivedICD10Data(state, {payload}) {
      console.debug("onReceivedICD10Data", payload);
      if (Array.isArray(payload)) {
        return {
          ...state,
          autocompleteOptions: {
            ...state.autocompleteOptions,
            icd10: payload,
          },
        };
      } else if (payload && payload.id > 0) {
        return {
          ...state,
          autocompleteOptions: {
            ...state.autocompleteOptions,
            icd10: [payload],
          },
        };
      } else {
        return {
          ...state,
          autocompleteOptions: {
            ...state.autocompleteOptions,
            icd10: [],
          },
        };
      }
    },
    resetMasterdataStore(state) {
      return initialState;
    },
    onReceivedPubMedData(state, {payload}) {
      state.pubMedData= payload;
    },
    onReceivedPubMedAbstract(state, {payload}) {
      return {
        ...state,
        pubMedAbstracts: {
          ...state.pubMedAbstracts,
          [payload.studyId]: payload.data,
        },
      };
    },
    onReceivedRehaData(state, {payload: {data, selected}}) {
      return {
        ...state,
        autocompleteOptions: {
          ...state.autocompleteOptions,
          reha: data,
        },
      };
    },
    onReceivedMigelData(state, {payload: {data, selected}}) {
      const selectedItem=data.filter((e) => e.article===selected)[0];
      return {
        ...state,
        autocompleteOptions: {
          ...state.autocompleteOptions,
          migel: data,
        },
        requiredMigelData: selectedItem?{
          ...state.requiredMigelData,
          limitatio_details: selectedItem.limitations,
          specialist_details: selectedItem.specialistinformation,
          required_migel_name: selectedItem.productname,
          pharma_email: selectedItem.pharmaCompanyMailAdress,
          pharma_company_name: selectedItem.prtnoPharmaCompany,
          optionInMigel: (data.find((e) => e.article===selected))? true:false,
          kn_id: selectedItem.kn_id,
        }:{},
      };
    },
    onReceivedDoctorData(state, {payload}) {
      return {
        ...state,
        autocompleteOptions: {
          ...state.autocompleteOptions,
          doctor: payload,
        },
      };
    },
    onReceivedAdditionalDrugInformation(state, action) {
      return {
        ...state,
        formComputedData: {
          pretreatment: action.payload,
          remarks_diagnosis: action.payload,
        },
      };
    },
    onReceivedBagDataCombined(state, {payload}) {
      return {
        ...state,
        autocompleteOptions: {
          ...state.autocompleteOptions,
          combinedDrug: payload,
        },
      };
    },

    onReceivedPatientFreeTextSearch(state, {payload}) {
      const patientSearchResults = payload.filter((e, index, array) =>
        index === array.findIndex((t) => (
          t.insurance_no === e.insurance_no &&
          t.name === e.name &&
          t.firstname === e.firstname &&
          t.insurance_company === e.insurance_company &&
          t.firstname === e.firstname &&
          t.city === e.city &&
          t.zip === e.zip &&
          t.street === e.street &&
          t.email_addr === e.email_addr
        )));


      return {
        ...state,
        autocompleteOptions: {
          ...state.autocompleteOptions,
          patientSearch: patientSearchResults,
        },
        isError: false,
      };
    },
    onReceivedPharmaCompanies(state, action) {
      const pharmaDict=Object.assign({},
          ...action.payload.map((x) => ({[x.name]: x.email})));
      return {
        ...state,
        pharmaCompanies: pharmaDict,
      };
    },
    onReceivedInsuranceCompanyData(state, {payload}) {
      return {
        ...state,
        autocompleteOptions: {
          ...state.autocompleteOptions,
          insuranceCompany: payload,
        },
      };
    },
    onReceivedBreathingDeviceData(state, {payload}) {
      return {
        ...state,
        breathingDevice: {
          ...state.breathingDevice,
          devices: payload,
        },
      };
    },
    onReceivedBreathingDeviceModesData(state, {payload}) {
      return {
        ...state,
        breathingDevice: {
          ...state.breathingDevice,
          modes: payload,
        },
      };
    },
  },
});


export const {
  onReceivedBagData,
  onReceivedICD10Data,
  onReceivedRehaData,
  onReceivedMigelData,
  onReceivedDoctorData,
  onReceivedAdditionalDrugInformation,
  onReceivedBagDataCombined,
  onReceivedPatientFreeTextSearch,
  onReceivedPharmaCompanies,
  onReceivedInsuranceCompanyData,
  resetMasterdataStore,
  onReceivedBreathingDeviceModesData,
  onReceivedBreathingDeviceData,
  onReceivedPubMedData,
  onReceivedPubMedAbstract,
  onReceivedCompendiumData,
} = MasterdataReducerSlices.actions;

export const selectInsuranceCompany = (state) => state.masterdata.autocompleteOptions?.insuranceCompany || [];
export const selectDiagnostics = (state) => state.masterdata?.autocompleteOptions?.icd10;
export const state_masterdata_autocompleteOptions_requiredDrug= (state) =>state.masterdata?.autocompleteOptions?.requiredDrug;
export const selectCombinedDrugOptions=(state) =>state.masterdata?.autocompleteOptions?.combinedDrug;
export const selectFirstDrugSearchResult=(state) =>state.masterdata?.autocompleteOptions?.requiredDrug?.[0];
export const selectcompendiumDataArray = (state) => state.masterdata.autocompleteOptions.compendiumData;
export const selectPatientSearchResult = (state) => state.masterdata.autocompleteOptions.patientSearch;
export const selectDoctorOptions = (state) =>state.masterdata?.autocompleteOptions?.doctor;

export default MasterdataReducerSlices.reducer;
