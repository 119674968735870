import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {tr} from "date-fns/locale";
import {t} from "i18next";
import {RootState} from "store/rootstate";

export interface CabometyxState {
  formData: {
    variant: string
    default: boolean,
    // first option list
    advancedRenalCellCarcinomaTreatmentRCC: boolean,
    hepatocellularCarcinomaTreatmentHCC: boolean,
    advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC: boolean,
    // second option list RCC = true
    monotherapyAfterTargetedVegfTherapyRCC: boolean,
    firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC: boolean,
    alternativeTherapyRCC: boolean,
    detailsTherapyTextRCC: string,
    // third option list HCC = true
    monotherapyAfterPriorSorafenibTreatmentHCC: boolean,
    alternativeTherapyHCC: boolean,
    detailsTherapyTextHCC: string,
    // fourth option list DTC = true
    monotherapyAfterProgressionAgainstVEGFDTC: boolean,
    alternativeTherapyDTC: boolean,
    alternativeTherapyTextDTC: string,

    defaultForm: {
      preTreatment: string
      patientCondition: string,
      prognosis: string,
      reasonsAlternativeTherapiesFailed: string,
    },
  }
}

const initialState:CabometyxState = {
  formData: {
    variant: "",
    default: false,
    // first option list
    advancedRenalCellCarcinomaTreatmentRCC: false,
    hepatocellularCarcinomaTreatmentHCC: false,
    advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC: false,
    // second option list RCC = true
    monotherapyAfterTargetedVegfTherapyRCC: false,
    firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC: false,
    alternativeTherapyRCC: false,
    detailsTherapyTextRCC: "",
    // third option list HCC = true
    monotherapyAfterPriorSorafenibTreatmentHCC: false,
    alternativeTherapyHCC: false,
    detailsTherapyTextHCC: "",
    // fourth option list DTC = true
    monotherapyAfterProgressionAgainstVEGFDTC: false,
    alternativeTherapyDTC: false,
    alternativeTherapyTextDTC: "",
    defaultForm: {
      preTreatment: "",
      patientCondition: "",
      prognosis: "",
      reasonsAlternativeTherapiesFailed: "",
    },
  },
};

const CabometyxFormSlice = createSlice({
  name: "Cabometyx",
  initialState,
  reducers: {
    onClearCabometyxState: (state)=>{
      state.formData = initialState.formData;
    },
    onReceiveSpecialMedCabometyxEditData: (state, {payload}: PayloadAction<any>) => {
      updatestate(payload, state);
      if (payload.formData?.defaultForm) {
        state.formData.defaultForm.preTreatment =
          payload.formData?.defaultForm?.preTreatment;
        state.formData.defaultForm.patientCondition =
          payload.formData?.defaultForm?.patientCondition;
        state.formData.defaultForm.reasonsAlternativeTherapiesFailed =
          payload.formData?.defaultForm?.reasonsAlternativeTherapiesFailed;
        state.formData.defaultForm.prognosis =
          payload.formData?.defaultForm?.prognosis;
      }
      if (payload.formData?.detailsTherapyTextRCC ) {
        state.formData.detailsTherapyTextRCC = payload.formData?.detailsTherapyTextRCC;
      }
      if (payload.formData?.detailsTherapyTextHCC ) {
        state.formData.detailsTherapyTextHCC = payload.formData?.detailsTherapyTextHCC;
      }
      if (payload.formData?.alternativeTherapyTextDTC) {
        state.formData.alternativeTherapyTextDTC = payload.formData?.alternativeTherapyTextDTC;
      }
    },
    onChangeCabometyxValue: (state, {payload}: PayloadAction<any>) => {
      if (payload.preTreatment || payload.preTreatment === "") state.formData.defaultForm.preTreatment = payload.preTreatment;
      if (payload.patientCondition || payload.patientCondition === "") state.formData.defaultForm.patientCondition = payload.patientCondition;
      if (payload.reasonsAlternativeTherapiesFailed || payload.reasonsAlternativeTherapiesFailed === "") state.formData.defaultForm.reasonsAlternativeTherapiesFailed = payload.reasonsAlternativeTherapiesFailed;
      if (payload.prognosis || payload.prognosis === "") state.formData.defaultForm.prognosis = payload.prognosis;
      if (payload.detailsTherapyTextRCC || payload.detailsTherapyTextRCC === "") state.formData.detailsTherapyTextRCC = payload.detailsTherapyTextRCC;
      if (payload.detailsTherapyTextHCC || payload.detailsTherapyTextHCC === "") state.formData.detailsTherapyTextHCC = payload.detailsTherapyTextHCC;
      if (payload.alternativeTherapyTextDTC || payload.alternativeTherapyTextDTC === "") state.formData.alternativeTherapyTextDTC = payload.alternativeTherapyTextDTC;
      updatestate(payload, state);
    },
  },
});


export const {
  onReceiveSpecialMedCabometyxEditData,
  onClearCabometyxState,
  onChangeCabometyxValue,
} = CabometyxFormSlice.actions;

export default CabometyxFormSlice.reducer;
export const selectCabometyxValues = (state:RootState) =>
  state.wizard.medicine.additionalInformation.cabometyxForm?.formData;


function updatestate(payload: any, state) {
  if (payload.formData?.variant || payload.formData?.variant === "") {
    state.formData.variant = payload.formData?.variant;
  }
  if (payload.formData?.default === true) { // DEFAULT
    state.formData.default = true;
    // first option list
    state.formData.advancedRenalCellCarcinomaTreatmentRCC = false;
    state.formData.hepatocellularCarcinomaTreatmentHCC = false;
    state.formData.advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC = false;
    // second option list RCC = true
    state.formData.monotherapyAfterTargetedVegfTherapyRCC =false;
    state.formData.firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC= false;
    state.formData.alternativeTherapyRCC = false;
    state.formData.detailsTherapyTextRCC = "";
    // third option list HCC = true
    state.formData.monotherapyAfterPriorSorafenibTreatmentHCC = false;
    state.formData.alternativeTherapyHCC = false;
    state.formData.detailsTherapyTextHCC = "";
    // fourth option list DTC = true
    state.formData.monotherapyAfterProgressionAgainstVEGFDTC =false;
    state.formData.alternativeTherapyTextDTC = "";
    state.formData.alternativeTherapyDTC = false;
  } else if (payload.formData?.default === false) {
    state.formData.default = false;
  }
  if (payload.formData?.advancedRenalCellCarcinomaTreatmentRCC === true) { // RCC
    state.formData.default = false;
    state.formData.hepatocellularCarcinomaTreatmentHCC = false;
    state.formData.advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC = false;
    state.formData.advancedRenalCellCarcinomaTreatmentRCC = true;
    // HCC
    state.formData.monotherapyAfterPriorSorafenibTreatmentHCC = false;
    state.formData.alternativeTherapyHCC = false;
    state.formData.detailsTherapyTextHCC = "";
    // DTC
    state.formData.monotherapyAfterProgressionAgainstVEGFDTC =false;
    state.formData.alternativeTherapyTextDTC = "";
    state.formData.alternativeTherapyDTC = false;
    // DEFAULT
    state.formData.defaultForm.preTreatment = "";
    state.formData.defaultForm.patientCondition = "";
    state.formData.defaultForm.reasonsAlternativeTherapiesFailed = "";
    state.formData.defaultForm.prognosis = "";
  }
  if (payload.formData?.hepatocellularCarcinomaTreatmentHCC === true) { // HCC
    state.formData.default = false;
    state.formData.advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC = false;
    state.formData.advancedRenalCellCarcinomaTreatmentRCC = false;
    state.formData.hepatocellularCarcinomaTreatmentHCC = true;
    // RTC
    state.formData.monotherapyAfterTargetedVegfTherapyRCC = false;
    state.formData.firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC = false;
    state.formData.alternativeTherapyRCC = false;
    state.formData.detailsTherapyTextRCC= "";
    // DTC
    state.formData.monotherapyAfterProgressionAgainstVEGFDTC =false;
    state.formData.alternativeTherapyTextDTC = "";
    state.formData.alternativeTherapyDTC = false;
    // DEFAULT
    state.formData.defaultForm.preTreatment = "";
    state.formData.defaultForm.patientCondition = "";
    state.formData.defaultForm.reasonsAlternativeTherapiesFailed = "";
    state.formData.defaultForm.prognosis = "";
  }
  if (payload.formData?.advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC === true) { // DTC
    state.formData.default = false;
    state.formData.advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC = true;
    state.formData.advancedRenalCellCarcinomaTreatmentRCC = false;
    state.formData.hepatocellularCarcinomaTreatmentHCC = false;
    // RTC
    state.formData.monotherapyAfterTargetedVegfTherapyRCC = false;
    state.formData.firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC = false;
    state.formData.alternativeTherapyRCC = false;
    state.formData.detailsTherapyTextRCC = "";
    // HCC
    state.formData.monotherapyAfterPriorSorafenibTreatmentHCC = false;
    state.formData.alternativeTherapyHCC = false;
    state.formData.detailsTherapyTextHCC = "";
    // DEFAULT
    state.formData.defaultForm.preTreatment = "";
    state.formData.defaultForm.patientCondition = "";
    state.formData.defaultForm.reasonsAlternativeTherapiesFailed = "";
    state.formData.defaultForm.prognosis = "";
  }

  // option list RCC = true
  if (payload.formData?.monotherapyAfterTargetedVegfTherapyRCC === true) {
    state.formData.monotherapyAfterTargetedVegfTherapyRCC = true;
    state.formData.firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC = false;
    state.formData.alternativeTherapyRCC = false;
  }
  if (payload.formData?.firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC === true) {
    state.formData.monotherapyAfterTargetedVegfTherapyRCC = false;
    state.formData.firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC = true;
    state.formData.alternativeTherapyRCC = false;
  }
  if (payload.formData?.alternativeTherapyRCC === true) {
    state.formData.monotherapyAfterTargetedVegfTherapyRCC = false;
    state.formData.firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC = false;
    state.formData.alternativeTherapyRCC = true;
  }

  // option list HCC = true

  if (payload.formData?.monotherapyAfterPriorSorafenibTreatmentHCC === true) {
    state.formData.monotherapyAfterPriorSorafenibTreatmentHCC = true;
    state.formData.alternativeTherapyHCC = false;
  }
  if (payload.formData?.alternativeTherapyHCC === true) {
    state.formData.monotherapyAfterPriorSorafenibTreatmentHCC = false;
    state.formData.alternativeTherapyHCC = true;
  }

  // option list DTC = true
  if (payload.formData?.monotherapyAfterProgressionAgainstVEGFDTC === true) {
    state.formData.monotherapyAfterProgressionAgainstVEGFDTC = true;
    state.formData.alternativeTherapyDTC = false;
    state.formData.alternativeTherapyTextDTC = "";
  }
  if (payload.formData?.alternativeTherapyDTC === true) {
    state.formData.monotherapyAfterProgressionAgainstVEGFDTC = false;
    state.formData.alternativeTherapyDTC = true;
  }
}
