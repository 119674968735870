import {
  post,
} from "store/api";


export const checkIsPraluent = (diagnosis, requiredDrug) => {
  let isPraluent=true;
  const icd=diagnosis?.code??diagnosis?.toString().split(" ")[0];
  const icdNumber= parseInt(icd?.match(/(\d+)/)?.[0]);
  const code=icd?.[0];

  if (!requiredDrug?.toString().includes("PRALUENT")) {
    isPraluent=false;
  }
  if (!(icdNumber>=780 && icdNumber<=790) && !(icdNumber>=78 && icdNumber<=79)) {
    isPraluent=false;
  }
  if (code!=="E") {
    isPraluent=false;
  }
  return isPraluent;
};

export const checkIsBrukinsa=(requiredDrug)=>{
  let isBrukinsa=false;
  if (requiredDrug?.toLowerCase().includes("brukinsa")) {
    isBrukinsa=true;
  }
  return isBrukinsa;
};

const BRUKINSA_TEXTS = ["cll", "sll", "kleinzelliges", "lymphatische"];

export const checkIsBrukinsaCll=(requiredDrug, diagnosis)=>{
  let isBrukinsaCll=false;
  if (requiredDrug?.toLowerCase().includes("brukinsa")) {
    BRUKINSA_TEXTS.forEach((element) => {
      if (diagnosis && diagnosis?.toLowerCase()?.includes(element)) {
        isBrukinsaCll = true;
      }
    });
  }
  return isBrukinsaCll;
};

/*
const ENHERTU_TEXTS = ["mamma", "mastocarcinoma", "carcinome mammaire",
  "brustkrebs", "breast cancer", "cancer du sein",
  "her2", "ihc"];


export const checkIsEnhertu = (requiredDrug, diagnosis) => {
  let isEnhertu=false;
  if (requiredDrug?.toLowerCase().includes("enhertu")) {
    ENHERTU_TEXTS.forEach((element) => {
      if (diagnosis && diagnosis?.toLowerCase()?.includes(element)) {
        isEnhertu = true;
      }
    });
  }
  return isEnhertu;
};
*/

export const checkIsEnhertu= async (requiredDrug) => {
  if (requiredDrug?.toLowerCase().includes("enhertu") ) {
    const isDiagnosis = await post("masterdata/isSpecialMed/", {med: "enhertu", key: ""});
    if (isDiagnosis?.results > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const QUVIVIC_TEXTS = [
  "insomni",
  "schlafstörung",
  "agrypnie",
  "hyposomnie",
  "schlaflosigkeit",
  "hyperarousal",
  "tagesmüdigkeit",
  "tagesschläfrigkeit",
  "dyssomnie",
  "einschlafstörung",
  "schlaf-Wach-Rhythmus",
  "durchschlafstöring",
  "troubles du sommeil",
  "agrypnie",
  "hyposomnie",
  "hyposomnie",
  "fatigue",
  "somnolence diurne",
  "troubles du sommeil",
  "endormissement",
  "rythme veille-sommeil",
  "continuité du sommeil",
  "Perturbation du rythme veille-sommeil",
  "insonnia",
  "disturbi del sonno",
  "agripnia",
  "iposomia",
  "iperattivazione",
  "sonnolenza diurna",
  "disomia",
  "sonno iniziale",
  "ritmo sonno veglia",
  "sonno intermedio",
  "ritmo sonno veglia",
];

export const checkIsQuviviq=(requiredDrug, diagnosis)=>{
  let isQuviviq=false;
  if (requiredDrug?.toLowerCase().includes("quviviq")) {
    QUVIVIC_TEXTS.forEach((element) => {
      if (diagnosis && diagnosis?.toLowerCase()?.includes(element)) {
        isQuviviq = true;
      }
    });
  }
  return isQuviviq;
};

export const checkIsImfinzi = async (requiredDrug) => {
  if (requiredDrug?.toLowerCase().includes("imfinzi") ) {
    const isDiagnosis = await post("masterdata/isSpecialMed/", {med: "imfinzi", key: ""});
    if (isDiagnosis?.results > 0) {
      console.log("checkIsTagrisso", isDiagnosis?.results > 0);
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const checkIsImjudo= async (requiredDrug) => {
  if (requiredDrug?.toLowerCase().includes("imjudo") ) {
    const isDiagnosis = await post("masterdata/isSpecialMed/", {med: "imjudo", key: ""});
    if (isDiagnosis?.results > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export const checkIsTagrisso= async (requiredDrug) => {
  if (requiredDrug?.toLowerCase().includes("tagrisso") ) {
    const isDiagnosis = await post("masterdata/isSpecialMed/", {med: "tagrisso", key: ""});
    if (isDiagnosis?.results > 0) {
      console.log("checkIsTagrisso", isDiagnosis?.results > 0);
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export const checkIsLynparza= async (requiredDrug) => {
  if (requiredDrug?.toLowerCase().includes("lynparza") ) {
    const isDiagnosis = await post("masterdata/isSpecialMed/", {med: "lynparza", key: ""});
    if (isDiagnosis?.results > 0) {
      console.log("checkIsLynparza", isDiagnosis?.results > 0);
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const checkIsIbrance = async (requiredDrug) => {
  if (requiredDrug?.toLowerCase().includes("ibrance") ) {
    const isDiagnosis = await post("masterdata/isSpecialMed/", {med: "ibrance", key: ""});
    if (isDiagnosis?.results > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const checkIsSomatuline = async (requiredDrug) => {
  if (requiredDrug?.toLowerCase().includes("somatuline") ) {
    const isDiagnosis = await post("masterdata/isSpecialMed/", {med: "somatuline", key: ""});
    if (isDiagnosis?.results > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const checkIsCabometyx = async (requiredDrug) => {
  if (requiredDrug?.toLowerCase().includes("cabometyx") ) {
    const isDiagnosis = await post("masterdata/isSpecialMed/", {med: "cabometyx", key: ""});
    if (isDiagnosis?.results > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const checkIsSpravato = async (requiredDrug) => {
  if (requiredDrug?.toLowerCase().includes("spravato") ) {
    const isDiagnosis = await post("masterdata/isSpecialMed/", {med: "spravato", key: ""});
    if (isDiagnosis?.results > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const checkIsCalquence = async (requiredDrug, key) => {
  if (requiredDrug?.toLowerCase().includes("calquence") && key?.length > 0) {
    key = key?.toLowerCase();
    key = key?.trim();
    const isDiagnosis = await post("masterdata/isSpecialMed/", {med: "calquence", key: key});
    if (isDiagnosis?.results > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const checkIsImbruvica = async (requiredDrug, key) => {
  console.log("checkIsImbruvica", requiredDrug, key);
  if (requiredDrug?.toLowerCase().includes("imbruvica") && key?.length > 0) {
    key = key?.toLowerCase();
    key = key?.trim();
    const result = await post("masterdata/isSpecialMed/", {med: "imbruvica", key: key});
    if (result?.results > 0) {
      return result.form;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
