import React from "react";
import {BsBarChart, BsFillChatSquareDotsFill, BsListTask, BsPencil, BsPeople} from "react-icons/bs";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import SingleRequestView from "pages/NewRequest/SingleRequest";
import RequestListView from "pages/RequestList/views/RequestListView";
import ImprintView from "pages/Imprint/imprint";
import SubstitutionView from "pages/Substitution/views/SubstitutionView";
import InsuranceResponseJWTView from "pages/JWTPages/InsuranceResponseJWT";
import PharmaResponseJWT from "pages/JWTPages/PharmaResponseJWT";
import CommunicationView from "pages/Communication/CommunicationView";
import HomeView from "pages/Home/Home";
import SignatureView from "pages/Signature/Signature.tsx";
import CommunicationMessage from "pages/Communication/components/ChatMessagesView";
import Wizard from "pages/Wizard/Wizard";
import MedicineFinder from "pages/MedicineFinder/MedicineFinder";
// import AI from "pages/AI/AI";
import {Unauthorized} from "../components/Unauthorized";

import {FaCapsules} from "components/icons";
import FactCheckIcon from "@mui/icons-material/FactCheck";

const home=(props)=> <><HomeView {...props}/></>;
const signature=(props)=>
  <div>
    <AuthenticatedTemplate>
      <SignatureView {...props}/>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate> <Unauthorized /></UnauthenticatedTemplate>
  </div>;


const communication=(props)=>
  <div>
    <AuthenticatedTemplate>
      <CommunicationView {...props}/>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Unauthorized />
    </UnauthenticatedTemplate>
  </div>;
const singlerequest=(props)=>
  <div>
    <AuthenticatedTemplate>
      <SingleRequestView {...props}/>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Unauthorized />
    </UnauthenticatedTemplate>
  </div>;
const requestlist=(props)=>
  <div>
    <AuthenticatedTemplate>
      <RequestListView {...props}/>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Unauthorized />
    </UnauthenticatedTemplate>
  </div>;
const substitution=(props)=>
  <div>
    <AuthenticatedTemplate>
      <SubstitutionView {...props}/>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Unauthorized />
    </UnauthenticatedTemplate>
  </div>;
const communicationMessage=(props)=>
  <div>
    <AuthenticatedTemplate>
      <CommunicationMessage {...props}/>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Unauthorized />
    </UnauthenticatedTemplate>
  </div>;

/* no authentication required */
const imprint=(props)=> <ImprintView {...props}/>;
const pharmaResponse=(props)=> <PharmaResponseJWT {...props}/>;
const insuranceResponse=(props)=> <InsuranceResponseJWTView {...props}/>;
const wizardView=(props)=> <Wizard {...props}/>;
const medicineFinderView=(props)=> <MedicineFinder {...props}/>;
// const ai = (props) => <AI {...props} />;

// Application Routes
export const routes = (i18n, props) => ([
  {
    "to": "/home",
    "aria-label": "home",
    "path": "/",
    "icon": BsBarChart,
    "name": i18n.t("defaultheader.link.statistics"),
    "needsVerification": false,
    "showInBreadcrumb": true,
    "component": home,
    "exact": true,
    "showInHeader": false,
    "demoOnly": false,
  },
  {
    "to": "/wizard",
    "aria-label": "wizard_edit",
    "path": "/wizard/copy_request/:requestId?",
    "name": i18n.t("defaultheader.link.wizard"),
    "needsVerification": false,
    "showInBreadcrumb": true,
    "component": wizardView,
    "exact": true,
    "icon": FaCapsules,
    "showInHeader": false,
    "demoOnly": false,
    "noLoginRequired": true,
    "fullScreen": true,
  },
  {
    "to": "/wizard",
    "aria-label": "wizard",
    "path": "/wizard/:requestId?/:sectionToScroll?",
    "name": i18n.t("defaultheader.link.wizard"),
    "needsVerification": false,
    "showInBreadcrumb": true,
    "component": wizardView,
    "exact": false,
    "icon": FaCapsules,
    "showInHeader": true,
    "demoOnly": false,
    "noLoginRequired": true,
    "fullScreen": true,
  },
  {
    "to": "/wizard",
    "aria-label": "wizard-med",
    "path": "/app/:medicineName?",
    "name": i18n.t("defaultheader.link.wizard"),
    "needsVerification": false,
    "showInBreadcrumb": false,
    "component": wizardView,
    "exact": false,
    "icon": FaCapsules,
    "showInHeader": false,
    "demoOnly": false,
    "noLoginRequired": true,
    "fullScreen": true,
  },
  {
    "to": "/medicheck",
    "aria-label": "medicheck-med",
    "path": "/medicheck/:medicineName?",
    "name": i18n.t("defaultheader.link.finder"),
    "needsVerification": false,
    "showInBreadcrumb": true,
    "component": medicineFinderView,
    "noLoginRequired": true,
    "exact": false,
    "showInHeader": true,
    "icon": FactCheckIcon,
    "demoOnly": false,
    "fullScreen": true,
  },
  {
    "to": "/signature",
    "aria-label": "signature",
    "path": "/signature",
    "name": i18n.t("defaultheader.link.signature"),
    "needsVerification": false,
    "showInBreadcrumb": true,
    "component": signature,
    "exact": true,
    "showInHeader": false,
    "demoOnly": false,
  },
  {
    "to": "/communication",
    "aria-label": "communication",
    "path": "/communication/:id",
    "exact": true,
    "name": i18n.t("defaultheader.link.communication"),
    "needsVerification": false,
    "showInBreadcrumb": true,
    "component": communicationMessage,
    "showInHeader": false,
    "demoOnly": false,
  },
  {
    "to": "/new_request",
    "aria-label": "new_request",
    "path": "/new_request",
    "exact": true,
    "icon": BsPencil,
    "name": i18n.t("defaultheader.link.other_request"),
    "needsVerification": false,
    "showInBreadcrumb": true,
    "component": singlerequest,
    "showInHeader": true,
    "demoOnly": false,
  },
  {
    "to": "/edit_request",
    "aria-label": "edit_request",
    "path": "/edit_request/:id/:formPage?",
    "exact": true,
    "name": i18n.t("defaultheader.link.editrequest"),
    "needsVerification": false,
    "showInBreadcrumb": true,
    "component": singlerequest,
    "showInHeader": false,
    "demoOnly": false,
  },
  {
    "to": "/new_request",
    "aria-label": "new_request_edit",
    "path": "/new_request/:id",
    "exact": true,
    "name": i18n.t("defaultheader.link.newrequest"),
    "needsVerification": false,
    "showInBreadcrumb": false,
    "component": singlerequest,
    "showInHeader": false,
    "demoOnly": false,
  },
  {
    "to": "/copy_request",
    "aria-label": "copy_request",
    "path": "/copy_request/:id",
    "exact": true,
    "name": i18n.t("defaultheader.link.copyrequest"),
    "needsVerification": false,
    "showInBreadcrumb": true,
    "component": singlerequest,
    "showInHeader": false,
    "demoOnly": false,
  },
  {
    "to": "/copy_request",
    "aria-label": "copy_request-id",
    "path": "/copy_request/:request/:step",
    "exact": true,
    "name": i18n.t("defaultheader.link.editrequest"),
    "needsVerification": false,
    "showInBreadcrumb": false,
    "component": singlerequest,
    "showInHeader": false,
    "demoOnly": false,
  },
  {
    "to": "/copy_request_praluent",
    "aria-label": "copy_request_praluent-id",
    "path": "/copy_request_praluent/:id",
    "exact": true,
    "name": i18n.t("defaultheader.link.copyrequest"),
    "needsVerification": false,
    "showInBreadcrumb": true,
    "component": singlerequest,
    "showInHeader": false,
    "demoOnly": false,
  },
  {
    "to": "/requests",
    "path": "/requests",
    "aria-label": "requestlist",
    "icon": BsListTask,
    "name": i18n.t("defaultheader.link.myrequests"),
    "needsVerification": false,
    "showInBreadcrumb": true,
    "component": requestlist,
    "exact": true,
    "showInHeader": true,
    "demoOnly": false,
  },
  {
    "to": "/substitution",
    "aria-label": "substitution",
    "path": "/substitution",
    "icon": BsPeople,
    "name": i18n.t("defaultheader.link.substitution"),
    "component": substitution,
    "needsVerification": true,
    "showInBreadcrumb": true,
    "showInHeader": false,
    "demoOnly": false,
  },
  {
    "to": "/imprint",
    "aria-label": "imprint",
    "path": "/imprint",
    "name": i18n.t("imprint.title"),
    "needsVerification": false,
    "showInBreadcrumb": true,
    "component": imprint,
    "showInHeader": false,
    "demoOnly": false,
  },
  {
    "to": "/uploadDocument",
    "aria-label": "uploadDocument",
    "path": "/uploadDocument/:token",
    "exact": false,
    "name": i18n.t("defaultheader.link.request.upload.files"),
    "needsVerification": false,
    "showInBreadcrumb": true,
    "component": pharmaResponse,
    "showInHeader": false,
    "demoOnly": false,
  },
  {
    "to": "/communicationResponse",
    "aria-label": "communicationResponse",
    "path": "/communicationResponse/:token",
    "exact": false,
    "name": i18n.t("defaultheader.link.request.communication"),
    "needsVerification": true,
    "showInBreadcrumb": true,
    "component": insuranceResponse,
    "showInHeader": false,
    "demoOnly": false,
  },
  {
    "to": "/communication",
    "aria-label": "communication",
    "path": "/communication",
    "icon": BsFillChatSquareDotsFill,
    "exact": true,
    "name": i18n.t("defaultheader.link.communication"),
    "component": communication,
    "needsVerification": true,
    "showInBreadcrumb": true,
    "showInHeader": false,
    "iconOnly": true,
    "demoOnly": false,
  },
  /*
  {
    "to": "/ai/",
    "aria-label": "ai",
    "path": "/ai/",
    "name": i18n.t("defaultheader.link.ai"),
    "needsVerification": false,
    "showInBreadcrumb": true,
    "component": ai,
    "noLoginRequired": true,
    "exact": false,
    "showInHeader": false,
    "icon": FactCheckIcon,
    "fullScreen": false,
  },
 */
]);

// Routes for navigation
export const GetHeaderRoutes=(verified, i18n) => routes(i18n).filter((e)=>e.showInHeader)
    .filter((e)=>verified?true:!e.needsVerification);

// RoutesFor the BreadCrumbs
export const getBreadCrumbRoutes=(t)=>routes(t).filter((e)=>e.showInBreadcrumb);


export const getUnauthenticatedRoutes=(i18n) => routes(i18n)
    .filter((e)=>e.showInHeader)
    .filter((e)=>e.noLoginRequired);
export const isRouteFullscreen = (location, i18n)=>
  routes(i18n)
      .filter((e)=>e.fullScreen)
      .reduce((a, e)=>a||location.includes(e.to), false);
