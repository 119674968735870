import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {UseAppSelector} from "store/hooks";
import {Accordion, Grid, AccordionDetails, AccordionSummary} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Parser} from "html-to-react";
import {
  selectMedicineValues,
  IMedStateSameSubstanceMeds,
  IMedStateInteraction,
  IMedStateLimtree,
} from "../medicine/medicineSlice";

import {
  selectCalquenceValues,
} from "./forms/Calquence/CalquenceSlice";


import {selectcompendiumDataArray} from "store/features/masterdataSlice";
import {ICompendiumData} from "store/rootstate";
import {selectSpecialAdditionalInformation, selectSpecialMedType, SpecialMedAdditionalInformation} from "./specialMedSlice";
import {textForSepecialMedications} from "./textForSepecialMedications";
import {selectDiagnosisValues} from "../Diagnosis/diagnosisSlice";
import WarningIcon from "@mui/icons-material/Warning";
import {useTheme} from "@mui/material/styles";


export const MedicineInfo = (props) => {
  const calquenceValues=UseAppSelector(selectCalquenceValues);
  const calquenceTreatment =
    calquenceValues.variant === 5 ? 2 :
    calquenceValues.variant === 7 ? 2 :
    calquenceValues.variant === 6 ? 1 :
    calquenceValues.variant === 4 ? 1 :
    0;

  const theme = useTheme();
  const isWizardIndex = window.location.pathname.indexOf("wizard") > 0;
  const isSmallScreen = isWizardIndex || window.innerWidth < 600;

  const htmlToReactParser = Parser();
  const {t, i18n}=useTranslation();
  const currentLang=i18n.language.toUpperCase();
  const medicineValues=UseAppSelector(selectMedicineValues);
  const diagnosisValues=UseAppSelector(selectDiagnosisValues);
  const compendiumDataArray = UseAppSelector<readonly ICompendiumData[]>(selectcompendiumDataArray);
  const additionalInformation=UseAppSelector(selectSpecialAdditionalInformation);
  const specialMedType=UseAppSelector(selectSpecialMedType);
  const relevance = (rlv) => {
    /*
    1 = Kontraindiziert
    2 = Nicht empfohlen oder Anpassung nötig
    3 = Überwachung/Anpassung
    4 = Bei Risikofaktor Überwachung/Anpassung
    5 = Vorsichtshalber überwachen
    6 = Keine Massnahmen
    */
    switch (rlv) {
      case 1:
        return t("rlv.contraindicated");
      case 2:
        return t("rlv.notRecommendedOrAdjustmentNeeded");
      case 3:
        return t("rlv.monitoringAdjustment");
      case 4:
        return t("rlv.monitorAdjustmentIfRiskFactor");
      case 5:
        return t("rlv.cautionaryMonitoring");
      case 6:
        return t("rlv.noInterventions");
      default:
        return "";
    }
  };

  const getPriceTypeLabel = (priceType: string) => {
    switch (priceType) {
      case "PEXF":
        return t("priceType.exFactory"); // Ex factory-Preis: Einkaufspreis des Grossisten bei der Firma (exkl. MwSt)
      case "PPHA":
        return t("priceType.wholesale"); // Einkaufspreis für Detailhandel bei der Firma (exkl. MwSt)
      case "PPUB":
        return t("priceType.retail"); // Publikumspreis, entspricht dem SL- Preis (Spezialitätenliste) gemäss BAG
      default:
        return "";
    }
  };


  return (

    <Grid item container xs={12} spacing={1}>
      {
        medicineValues?.medicationShortageWarning &&
        medicineValues?.medicationShortageWarning.length > 0 &&
       <Grid item xs={12}>
         <Accordion disableGutters
           sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />}
             aria-controls="medicationShortageWarningt" id="medicationShortageWarning-header">
             <Typography variant="h5">{t("fach.shortageWarning")}</Typography>
           </AccordionSummary>
           <AccordionDetails sx={{minWidth: "100%"}} >
             {medicineValues.medicationShortageWarning}
           </AccordionDetails>
         </Accordion>

       </Grid>}


      {
        medicineValues?.professionalPatientInformationInHtmlFormat&&
      <Grid item xs={12}>
        <Accordion
          disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />}
            aria-controls="PatientInformation" id="PatientInformation-header">
            <Typography variant="h5">{t("limitation.details")}</Typography>
          </AccordionSummary>
          <AccordionDetails >
            <Typography variant="h6">{t("limitation.header2")}</Typography>
            {(
              typeof htmlToReactParser?.parse === "function"&&
              htmlToReactParser?.parse(medicineValues?.professionalPatientInformationInHtmlFormat))||
              ""}
          </AccordionDetails>
        </Accordion>
      </Grid>
      }
      {medicineValues?.limtree&&medicineValues?.limtree.length > 0 &&
       medicineValues?.limtree[0].LIMITATIONSGERMAN.length > 0 && <Grid item xs={12}>
        <Accordion disableGutters
          sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />}
            aria-controls="limmitations-content" id="limmitations-header">
            <Typography variant="h5">{t("fach.limitation")}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{minWidth: "100%"}} >
            <Grid container direction={"column"} spacing={1}>
              {[...medicineValues?.limtree || []]
                  .sort((a: IMedStateLimtree, b: IMedStateLimtree) => {
                    if (a.INDCDSTRING < b.INDCDSTRING) return -1;
                    if (a.INDCDSTRING > b.INDCDSTRING) return 1;
                    return 0;
                  })

                  ?.map((limitation:IMedStateLimtree, index:number) => {
                    return <Grid key={`sameMedicine_${index}`} item >
                      {currentLang !== "FR" &&
                    <Typography variant="body1" sx={{textDecoration: "none"}} display="inline">
                      <strong>
                        {limitation?.INDCDSTRING}&nbsp;</strong>
                      {(typeof htmlToReactParser?.parse === "function"&&htmlToReactParser?.parse(limitation?.LIMITATIONSGERMAN)) || ""}
                    </Typography>
                      }
                      {currentLang === "FR" &&
                   <Typography variant="body1" sx={{textDecoration: "none"}} display="inline">
                     <strong>
                       {limitation?.INDCDSTRING}&nbsp;</strong>
                     {(typeof htmlToReactParser?.parse === "function"&&htmlToReactParser?.parse(limitation?.LIMITATIONSFRENCH)) || ""}
                   </Typography>
                      }
                    </Grid>;
                  })}
            </Grid>


          </AccordionDetails>
        </Accordion></Grid>}


      {specialMedType === "Imfinzi" && additionalInformation !== SpecialMedAdditionalInformation.NONE && <Grid item xs={12}>
        <Accordion
          disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />}
            aria-controls="AdditionalInformation" id="AdditionalInformation-header">
            <Typography variant="h5">{t("specialMedData.details")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {
              (
                additionalInformation === SpecialMedAdditionalInformation.IMFINZI_AEGEN ) ?
                t("specialMedData.imfinzi.aegen") :
                htmlToReactParser?.parse(textForSepecialMedications[`IMFINZI_${additionalInformation}_${currentLang}`])
            }
            {
              additionalInformation === SpecialMedAdditionalInformation.IMJUDO_POSEIDON&&
                t("specialMedData.imjudo.poseidon")
            }
            {
              additionalInformation === SpecialMedAdditionalInformation.IMFINZI_ADREIATIC&&
                t("specialMedData.imfinzi.adriatic")
            }
          </AccordionDetails>
        </Accordion>
      </Grid>}

      {specialMedType === "Imjudo" && additionalInformation !== SpecialMedAdditionalInformation.NONE &&<Grid item xs={12}>
        <Accordion
          disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
          <AccordionSummary

            expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />} aria-controls="AdditionalInformation" id="AdditionalInformation-header">
            <Typography variant="h5">{t("specialMedData.details")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {
              typeof htmlToReactParser?.parse === "function" &&
              htmlToReactParser?.parse(textForSepecialMedications[`IMJUDO_${additionalInformation}_${currentLang}`])
            }
            {
              additionalInformation === SpecialMedAdditionalInformation.IMJUDO_POSEIDON&&
                t("specialMedData.imjudo.poseidon")
            }
          </AccordionDetails>
        </Accordion></Grid>}
      {
        console.debug("specialMedType", specialMedType, additionalInformation) }
      {
        (
          (specialMedType === "Tagrisso" && additionalInformation === SpecialMedAdditionalInformation.TAGRISSO_ADAURA) ||
          (specialMedType === "Tagrisso" && additionalInformation === SpecialMedAdditionalInformation.TAGRISSO_FLAURA2)
        ) &&
        <Grid item xs={12}>
          <Accordion
            disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />} aria-controls="AdditionalInformation" id="AdditionalInformation-header">
              <Typography variant="h5">{t("specialMedData.details")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" sx={{textDecoration: "none"}} display="inline">
                {t("tagrisso.additionalInformation")}
              </Typography>
            </AccordionDetails>
          </Accordion></Grid>}
      {
        (
          (specialMedType === "Lynparza" && additionalInformation === SpecialMedAdditionalInformation.LYNPARZA_OLYMPIA) ||
          (specialMedType === "Lynparza" && additionalInformation === SpecialMedAdditionalInformation.LYNPARZA_SOLO) ||
          (specialMedType === "Lynparza" && additionalInformation === SpecialMedAdditionalInformation.LYNPARZA_PAOLA)
        ) &&
        <Grid item xs={12}>
          <Accordion
            disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />} aria-controls="AdditionalInformation" id="AdditionalInformation-header">
              <Typography variant="h5">{t("specialMedData.details")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" sx={{textDecoration: "none"}} display="inline">
                {t("Lynparza.additionalInformation")}
              </Typography>
            </AccordionDetails>
          </Accordion></Grid>}


      {
        (
          (specialMedType === "Ibrance" && additionalInformation === SpecialMedAdditionalInformation.IBRANCE)
        ) &&
        <Grid item xs={12}>
          <Accordion
            disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />} aria-controls="AdditionalInformation" id="AdditionalInformation-header">
              <Typography variant="h5">{t("specialMedData.details")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" sx={{textDecoration: "none"}} display="inline">
                {t("Ibrance.additionalInformation")}
              </Typography>
            </AccordionDetails>
          </Accordion></Grid>}

      {specialMedType === "Somatuline" && (
        additionalInformation === SpecialMedAdditionalInformation.SOMATULINE_CLARINET
      )&&
      <Grid item xs={12}>
        <Accordion
          disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
          <AccordionSummary

            expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />} aria-controls="AdditionalInformation" id="AdditionalInformation-header">
            <Typography variant="h5">{t("specialMedData.details")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" sx={{textDecoration: "none"}} display="inline">
              {t("somatuline.clarinet.summary")}
            </Typography>
          </AccordionDetails>
        </Accordion></Grid>}


      {specialMedType === "Cabometyx" && (
        additionalInformation === SpecialMedAdditionalInformation.CABOMETYX_1 ||
        additionalInformation === SpecialMedAdditionalInformation.CABOMETYX_2 ||
        additionalInformation === SpecialMedAdditionalInformation.CABOMETYX_3 ||
        additionalInformation === SpecialMedAdditionalInformation.CABOMETYX_4 ||
        additionalInformation === SpecialMedAdditionalInformation.CABOMETYX_5 ||
        additionalInformation === SpecialMedAdditionalInformation.CABOMETYX_6

      )&&
      <Grid item xs={12}>
        <Accordion
          disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
          <AccordionSummary

            expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />} aria-controls="AdditionalInformation" id="AdditionalInformation-header">
            <Typography variant="h5">{t("specialMedData.details")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" sx={{textDecoration: "none"}} display="inline">
              {additionalInformation === SpecialMedAdditionalInformation.CABOMETYX_1 && t("cabometyx.sudy.summary.1")}
              {additionalInformation === SpecialMedAdditionalInformation.CABOMETYX_2 && t("cabometyx.sudy.summary.2")}
              {additionalInformation === SpecialMedAdditionalInformation.CABOMETYX_3 && t("cabometyx.sudy.summary.3")}
              {additionalInformation === SpecialMedAdditionalInformation.CABOMETYX_4 && t("cabometyx.sudy.summary.4")}
              {additionalInformation === SpecialMedAdditionalInformation.CABOMETYX_5 && t("cabometyx.sudy.summary.5")}
              {additionalInformation === SpecialMedAdditionalInformation.CABOMETYX_6 && t("cabometyx.sudy.summary.6")}
            </Typography>
          </AccordionDetails>
        </Accordion></Grid>}
      {specialMedType === "Spravato" &&
      <Grid item xs={12}>
        <Accordion
          disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />} aria-controls="AdditionalInformation" id="AdditionalInformation-header">
            <Typography variant="h5">{t("specialMedData.details")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" sx={{textDecoration: "none"}} display="inline">
              {t("spravato.summary")}
            </Typography>
          </AccordionDetails>
        </Accordion></Grid>}

      {specialMedType === "Calquence" && (calquenceTreatment === 1 || calquenceTreatment === 2) &&<Grid item xs={12}>
        <Accordion
          disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
          <AccordionSummary

            expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />} aria-controls="AdditionalInformation" id="AdditionalInformation-header">
            <Typography variant="h5">{t("specialMedData.details")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {
              typeof htmlToReactParser?.parse === "function" &&
              htmlToReactParser?.parse(textForSepecialMedications[`CALQUENCE_TPYE_${calquenceTreatment}_${currentLang}`])
            }
          </AccordionDetails>
        </Accordion></Grid>}

      {specialMedType === "Imbruvica" &&<Grid item xs={12}>
        <Accordion
          disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
          <AccordionSummary

            expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />} aria-controls="AdditionalInformation" id="AdditionalInformation-header">
            <Typography variant="h5">{t("specialMedData.details")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {(
              typeof htmlToReactParser?.parse === "function"&&
              htmlToReactParser?.parse(
                  textForSepecialMedications[`IMBRUVICA_${currentLang}`]))||
            ""}
          </AccordionDetails>
        </Accordion></Grid>}


      {specialMedType === "Quviviq" && additionalInformation !== SpecialMedAdditionalInformation.NONE &&<Grid item xs={12}>
        <Accordion
          disableGutters sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
          <AccordionSummary

            expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />} aria-controls="AdditionalInformation" id="AdditionalInformation-header">
            <Typography variant="h5">{t("specialMedData.details")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {(
              typeof htmlToReactParser?.parse === "function"&&
              htmlToReactParser?.parse(
                  textForSepecialMedications[`${SpecialMedAdditionalInformation[additionalInformation]}_${currentLang}`]))||
            ""}
          </AccordionDetails>
        </Accordion></Grid>}

      {medicineValues?.productsInteraction?.length > 0 && medicineValues?.productsInteraction[0]?.TITD?.length > 0 &&
       <Grid item xs={12}>
         <Accordion disableGutters defaultExpanded={false}
           sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />}
             aria-controls="productsInteraction" id="panel2d-header">
             <Typography variant="h5">{t("fach.risiken")}</Typography>
           </AccordionSummary>
           <AccordionDetails>
             <Typography>{t("fach.risiken.source")}</Typography>
             <Box sx={{m: 1}}/>
             <Grid container direction={"column"} spacing={1}>
               {medicineValues?.productsInteraction?.map((interaction:IMedStateInteraction, index:number) => {
                 return <Grid key={`sameMedicine_${index}`} item >
                   <Typography variant="body1" sx={{textDecoration: "none", fontWeight: "bold"}} >
                     {relevance(interaction?.RLV)}:&nbsp;
                     {currentLang === "FR" && interaction?.TITF}
                     {currentLang !== "FR" && interaction?.TITD}
                   </Typography>
                   {currentLang !== "FR" &&
                    <Typography variant="body1" sx={{textDecoration: "none"}} display="inline">
                      {interaction?.EFFTXTD}&nbsp;
                      {interaction?.MEASD}&nbsp;
                      {interaction?.MECHD}&nbsp;
                    </Typography>
                   }
                   {currentLang === "FR" &&
                   <Typography variant="body1" sx={{textDecoration: "none"}} display="inline">
                     {interaction?.EFFTXTF}&nbsp;
                     {interaction?.MEASF}&nbsp;
                     {interaction?.MECHF}&nbsp;
                   </Typography>
                   }
                 </Grid>;
               })}
             </Grid>
           </AccordionDetails>
         </Accordion>
       </Grid>}


      {diagnosisValues?.diagnosis&&compendiumDataArray &&
       compendiumDataArray.length > 0 &&
       <Grid item xs={12}>
         <Accordion disableGutters
           sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon sx={{borderRadius: "4px", background: "hsl(0deg 0% 0% / 0.1)"}} />}
             aria-controls="productsInteraction" id="panel2d-header">
             <Typography variant="h5">{t("compendiumDataArray")}</Typography>
           </AccordionSummary>
           <AccordionDetails>
             <Grid container direction={"column"} spacing={1}>
               {compendiumDataArray?.map && compendiumDataArray?.map((compendium:ICompendiumData, index:number) => {
                 return <Grid key={`sameMedicine_${index}`} item >
                   <Typography variant="body1" sx={{textDecoration: "none"}}>
                     {compendium.NAME}&nbsp;-&nbsp;{compendium.manufacturer})
                   </Typography>
                 </Grid>;
               })}
             </Grid>
           </AccordionDetails>
         </Accordion>
       </Grid>}


      {medicineValues?.sameSubstanceMeds?.length > 0 && (

        <Grid item xs={12}>
          <Accordion
            disableGutters
            sx={{width: "100%", backgroundColor: "#e8f4ec", boxShadow: "none"}}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    borderRadius: "4px",
                    background: "hsl(0deg 0% 0% / 0.1)",
                  }}
                />
              }
              aria-controls="sameSubstanceMeds"
              id="sameSubstanceMeds-header"
            >
              <Typography variant="h5">{t("fach.sameSubstanceMeds")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {isSmallScreen ? (
            <Typography variant="body1">
              {medicineValues?.sameSubstanceMeds
                  .slice()
                  .sort((a, b) => {
                    if (a.PRICE && b.PRICE) {
                      return a.PRICE - b.PRICE;
                    } else if (a.PRICE) {
                      return -1;
                    } else if (b.PRICE) {
                      return 1;
                    } else {
                      return 0;
                    }
                  })
                  // .filter((drug) => drug.PTYP === "PPUB")
                  .map((drug, index) => {
                    const description =
                    currentLang !== "FR" ?
                      drug?.DSCRD?.replace(/"/g, "") :
                      drug?.DSCRF?.replace(/"/g, "");
                    const name =
                    currentLang !== "FR" ?
                      drug?.NAMD?.replace(/"/g, "") :
                      drug?.NAMF?.replace(/"/g, "");
                    const price = drug?.PRICE ? `${drug?.PRICE} CHF` : "";
                    const priceTypeLabel = getPriceTypeLabel(drug?.PTYP);
                    const shortageWarning =
                    drug?.medicationShortageWarning &&
                    drug?.medicationShortageWarning.length > 0 ?
                      "⚠️" :
                      "";

                    return (
                      `${description} (${drug?.PHARMACODE}) (${name}) ${price} ${priceTypeLabel} ${shortageWarning}`
                    );
                  })
                  .join(", ")}
            </Typography>
          ) : (
            <Grid container direction={"column"} spacing={1}>
              {medicineValues?.sameSubstanceMeds
                  .slice()
                  .sort((a, b) => {
                    if (a.PRICE && b.PRICE) {
                      return a.PRICE - b.PRICE;
                    } else if (a.PRICE) {
                      return -1;
                    } else if (b.PRICE) {
                      return 1;
                    } else {
                      return 0;
                    }
                  })
                  // .filter((drug) => drug.PTYP === "PPUB")
                  .map((drug, index) => (
                    <Grid key={`sameMedicine_${index}`} container item spacing={2}>
                      <Grid item xs={4}>
                        <Typography variant="body1" sx={{textDecoration: "none"}}>
                          {currentLang !== "FR" ?
                          drug?.DSCRD?.replace(/"/g, "") + " " :
                          drug?.DSCRF?.replace(/"/g, "") + " "}
                        </Typography>
                        {drug?.PHARMACODE && (
                          <Typography variant="body1" sx={{textDecoration: "none"}}>
                          ({drug?.PHARMACODE})
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body1" sx={{textDecoration: "none"}}>
                        (
                          {currentLang !== "FR" ?
                          drug?.NAMD?.replace(/"/g, "") :
                          drug?.NAMF?.replace(/"/g, "")}
                        )
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body1" sx={{textDecoration: "none"}}>
                          {drug?.PRICE ? `${drug?.PRICE} CHF` : "n/a"}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body1" sx={{textDecoration: "none"}}>
                          {getPriceTypeLabel(drug?.PTYP)}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        {drug?.medicationShortageWarning !== null &&
                      drug?.medicationShortageWarning?.length > 0 ? (
                        <WarningIcon
                          fontSize={"small"}
                          style={{color: theme.palette.warning.main}}
                        />
                      ) : (
                        <div />
                      )}
                      </Grid>
                    </Grid>
                  ))}
            </Grid>
          )}
            </AccordionDetails>
          </Accordion>
        </Grid>


      )}


    </Grid>
  );
};
export default MedicineInfo;


