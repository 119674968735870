import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Grid, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import {setCombinedMedication} from "pages/Wizard/wizardSlice";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {onChangeDosisValue} from "../../../Dosis/dosisPlanSlice";
import WarningIcon from "@mui/icons-material/Warning";
import Stack from "@mui/material/Stack";
import {
  useLocation,
} from "react-router-dom";

import {
  UseAppDispatch,
  UseAppSelector,
} from "store/hooks";

import {
  onChangeCabometyxValue,
  selectCabometyxValues,
} from "./CabometyxSlice";
import {
  onChangeKVVLegalState,
  onChangeMedicineValue,
} from "../../../medicine/medicineSlice";
import {
  setStudyById,
  removeStudy,
} from "pages/Wizard/wizardSlice";
import {
  onChangeTreatmentValue,
} from "../../../Treatment/treatmentPlanSlice";
import {
  onChangeSpecialMedValue,
  SpecialMedAdditionalInformation,
} from "../../specialMedSlice";
import {tr} from "date-fns/locale";

const CabometyxForm = (props) => {
  const handleDosisValueChange = (field:string, value:any)=>dispatch(onChangeDosisValue({field, value}));
  const {t} = useTranslation();
  const dispatch = UseAppDispatch();
  const CabometyxValues = UseAppSelector(selectCabometyxValues);
  const handleValueChangeKVV = (field:string, value:any)=>dispatch(onChangeTreatmentValue({field, value}));
  const handleSpecialMedValueChange = (field:string, value:any)=>dispatch(onChangeSpecialMedValue({field, value}));
  const params=useLocation();

  const handleOfflabel = () => {
    dispatch(onChangeKVVLegalState( {limitatio_approval: false, specialist_info: false}));
    handleValueChangeKVV("limitatio_approval", false);
    handleValueChangeKVV("specialist_info", false);
  };


  useEffect(() => {
    const regex = /\d+$/;
    const isEditId = regex.test(params.pathname);
    console.debug("isEditId", isEditId);
    if (!isEditId) {
      dispatch(onChangeKVVLegalState(
          {
            limitatio_approval: false,
            specialist_info: false,
          },
      ));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", false);
    }
  }, []);


  useEffect(() => {
    dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
    handleDosisValueChange("dosis", "");
    dispatch(setCombinedMedication({medication: ""}));
    handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.NONE);
    if (
      CabometyxValues.advancedRenalCellCarcinomaTreatmentRCC === true &&
      CabometyxValues.monotherapyAfterTargetedVegfTherapyRCC === true) {
      // Variant 1 RCC
      dispatch(removeStudy({}));
      dispatch(onChangeCabometyxValue({formData: {variant: "1"}}));
      handleDosisValueChange("dosis", t(""));
      dispatch(onChangeKVVLegalState({limitatio_approval: true, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", true);
      handleValueChangeKVV("specialist_info", true);
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.NONE);
    } else if (
      CabometyxValues.advancedRenalCellCarcinomaTreatmentRCC === true &&
      CabometyxValues.firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC === true) {
      // Variant 2 RCC
      dispatch(onChangeCabometyxValue({formData: {variant: "2"}}));
      handleDosisValueChange("dosis", t("cabometyx.rcc.dose2"));
      dispatch(setCombinedMedication({medication: t("cabometyx.inCombinationWithNivolumab.medication")}));
      dispatch(setStudyById({studyId: "38642472"}));
      dispatch(onChangeKVVLegalState({limitatio_approval: false, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.CABOMETYX_2);
    } else if (
      CabometyxValues.advancedRenalCellCarcinomaTreatmentRCC === true &&
      CabometyxValues.alternativeTherapyRCC === true) {
      // Variant 3 RCC
      dispatch(onChangeCabometyxValue({formData: {variant: "3"}}));
      handleOfflabel();
      dispatch(removeStudy({}));
      handleDosisValueChange("dosis", t(""));
    } else if (
      CabometyxValues.hepatocellularCarcinomaTreatmentHCC === true &&
      CabometyxValues.monotherapyAfterPriorSorafenibTreatmentHCC === true) {
      // Variant 4 HCC
      dispatch(onChangeCabometyxValue({formData: {variant: "4"}}));
      dispatch(onChangeKVVLegalState( {limitatio_approval: false, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
      handleDosisValueChange("dosis", t("cabometyx.hcc.dose"));
      dispatch(setStudyById({studyId: "29972759"}));
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.CABOMETYX_4);
    } else if (
      CabometyxValues.hepatocellularCarcinomaTreatmentHCC === true &&
      CabometyxValues.alternativeTherapyHCC === true) {
      // Variant 5 HCC
      dispatch(onChangeCabometyxValue({formData: {variant: "5"}}));
      handleOfflabel();
      dispatch(removeStudy({}));
      handleDosisValueChange("dosis", t(""));
    } else if (
      CabometyxValues.advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC === true &&
      CabometyxValues.monotherapyAfterProgressionAgainstVEGFDTC === true) {
      // Variant 6 DCC
      dispatch(onChangeCabometyxValue({formData: {variant: "6"}}));
      dispatch(onChangeKVVLegalState( {limitatio_approval: false, specialist_info: true}));
      handleValueChangeKVV("limitatio_approval", false);
      handleValueChangeKVV("specialist_info", true);
      handleDosisValueChange("dosis", t("cabometyx.dtc.dose"));
      dispatch(setStudyById({studyId: "36259380"}));
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.CABOMETYX_6);

      // TODO: ADD TEXT TO ADDITIONAL DATA
      // TODO: ADD TEXT BOX Bitte begründen Sie die Wahl der Therapie
    } else if (
      CabometyxValues.advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC === true &&
      CabometyxValues.alternativeTherapyDTC === true) {
      // Variant 7 DCC
      dispatch(onChangeCabometyxValue({formData: {variant: "7"}}));
      handleOfflabel();
      dispatch(removeStudy({}));
      handleDosisValueChange("dosis", t(""));
    } else {
      // Variant ELSE

      dispatch(onChangeCabometyxValue({formData: {variant: ""}}));
      handleOfflabel();
      dispatch(removeStudy({}));
      dispatch(onChangeMedicineValue({field: "kn_id", value: ""}));
      handleDosisValueChange("dosis", "");
      handleSpecialMedValueChange("special_med_additional_information", SpecialMedAdditionalInformation.NONE);
    }
  },
  [
    CabometyxValues.default,
    CabometyxValues.advancedRenalCellCarcinomaTreatmentRCC, // RCC
    CabometyxValues.hepatocellularCarcinomaTreatmentHCC, // HCC
    CabometyxValues.advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC, // DTC
    // second option list RCC = true
    CabometyxValues.monotherapyAfterTargetedVegfTherapyRCC,
    CabometyxValues.firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC,
    CabometyxValues.alternativeTherapyRCC,
    CabometyxValues.detailsTherapyTextRCC,
    // third option list HCC = true
    CabometyxValues.monotherapyAfterPriorSorafenibTreatmentHCC,
    CabometyxValues.detailsTherapyTextHCC,
    CabometyxValues.alternativeTherapyHCC,
    // fourth option list DTC = true
    CabometyxValues.monotherapyAfterProgressionAgainstVEGFDTC,
    CabometyxValues.alternativeTherapyTextDTC,
    CabometyxValues.alternativeTherapyDTC,
  ]);


  return <>
    <Grid item xs={12}>
      <FormControl>
        <FormLabel>{t("cabometyx.options")}</FormLabel>
        <RadioGroup
          onChange={(event, value) => {
            if (value === "default") {
              dispatch(onChangeCabometyxValue({formData: {default: true}}));
            } else if (value === "advancedRenalCellCarcinomaTreatmentRCC") {
              dispatch(onChangeCabometyxValue({formData: {advancedRenalCellCarcinomaTreatmentRCC: true}}));
            } else if (value === "hepatocellularCarcinomaTreatmentHCC") {
              dispatch(onChangeCabometyxValue({formData: {hepatocellularCarcinomaTreatmentHCC: true}}));
            } else if (value === "advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC") {
              dispatch(onChangeCabometyxValue({formData: {advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC: true}}));
            }
          }}>
          <FormControlLabel
            value="advancedRenalCellCarcinomaTreatmentRCC"
            control={<Radio
              checked={CabometyxValues.advancedRenalCellCarcinomaTreatmentRCC}
              data-cy="advancedRenalCellCarcinomaTreatmentRCC" />}
            label={t("cabometyx.advancedRenalCellCarcinomaTreatmentRCC")}
          />
          <FormControlLabel
            value="hepatocellularCarcinomaTreatmentHCC"
            control={<Radio
              checked={CabometyxValues.hepatocellularCarcinomaTreatmentHCC}
              data-cy="hepatocellularCarcinomaTreatmentHCC" />}
            label={t("cabometyx.hepatocellularCarcinomaTreatmentHCC")}
          />
          <FormControlLabel
            value="advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC"
            control={<Radio
              checked={CabometyxValues.advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC}
              data-cy="advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC" />}
            label={t("cabometyx.advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC")}
          />
          <FormControlLabel
            value="default"
            control={<Radio
              checked={CabometyxValues.default}
              data-cy="default" />}
            label={t("cabometyx.default")}
          />
        </RadioGroup>
      </FormControl>
      {
        /*
        // second option list RCC = true
        CabometyxValues.monotherapyAfterTargetedVegfTherapyRCC,
        CabometyxValues.firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC,
        CabometyxValues.alternativeTherapyRCC,
        CabometyxValues.detailsTherapyTextRCC,
        */
      }
      <div
        hidden={
          !(CabometyxValues.advancedRenalCellCarcinomaTreatmentRCC === true)}>

        <FormLabel>{t("cabometyx.rcc")}</FormLabel>
        <RadioGroup
          onChange={(event, value) => {
            if (value === "monotherapyAfterTargetedVegfTherapyRCC") {
              dispatch(onChangeCabometyxValue({formData: {monotherapyAfterTargetedVegfTherapyRCC: true}}));
            } else if (value === "firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC") {
              dispatch(onChangeCabometyxValue({formData: {firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC: true}}));
            } else if (value === "alternativeTherapyRCC") {
              dispatch(onChangeCabometyxValue({formData: {alternativeTherapyRCC: true}}));
            }
          }}>
          <FormControlLabel
            value="monotherapyAfterTargetedVegfTherapyRCC"
            control={<Radio
              checked={CabometyxValues.monotherapyAfterTargetedVegfTherapyRCC}
              data-cy="monotherapyAfterTargetedVegfTherapyRCC" />}
            label={t("cabometyx.monotherapyAfterTargetedVegfTherapyRCC")}
          />
          <FormControlLabel
            value="firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC"
            control={<Radio
              checked={CabometyxValues.firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC}
              data-cy="firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC" />}
            label={t("cabometyx.firstLineTreatmentWithNivolumabIntermediatePoorRiskRCC")}
          />
          <FormControlLabel
            value="alternativeTherapyRCC"
            control={<Radio
              checked={CabometyxValues.alternativeTherapyRCC}
              data-cy="alternativeTherapyRCC" />}
            label={t("cabometyx.alternativeTherapyRCC")}
          />
        </RadioGroup>
        <Typography variant="body1" sx={{textDecoration: "none"}} display="inline">
          {CabometyxValues.variant === "1" && t("cabometyx.detailsTherapyTextRCC1")}
          {CabometyxValues.variant === "2" && t("cabometyx.detailsTherapyTextRCC2")}
        </Typography>
        <Box sx={{m: 1}}/>
        <TextField
          hidden={
            !(CabometyxValues.variant === "2" || CabometyxValues.variant === "1")
          }
          style={{width: "100%"}}
          label={"-"}
          InputLabelProps={{shrink: true}}
          value={CabometyxValues.detailsTherapyTextRCC }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeCabometyxValue(
                {
                  detailsTherapyTextRCC: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
      </div>

      {
        /*
        // third option list HCC = true
        CabometyxValues.monotherapyAfterPriorSorafenibTreatmentHCC,
        CabometyxValues.detailsTherapyTextHCC,
        CabometyxValues.alternativeTherapyHCC,


        */
      }
      <div
        hidden={
          !(CabometyxValues.hepatocellularCarcinomaTreatmentHCC === true)}>

        <FormLabel>{t("cabometyx.hcc")}</FormLabel>
        <RadioGroup
          onChange={(event, value) => {
            if (value === "monotherapyAfterPriorSorafenibTreatmentHCC") {
              dispatch(onChangeCabometyxValue({formData: {monotherapyAfterPriorSorafenibTreatmentHCC: true}}));
            } else if (value === "alternativeTherapyHCC") {
              dispatch(onChangeCabometyxValue({formData: {alternativeTherapyHCC: true}}));
            }
          }}>
          <FormControlLabel
            value="monotherapyAfterPriorSorafenibTreatmentHCC"
            control={<Radio
              checked={CabometyxValues.monotherapyAfterPriorSorafenibTreatmentHCC}
              data-cy="monotherapyAfterPriorSorafenibTreatmentHCC" />}
            label={t("cabometyx.monotherapyAfterPriorSorafenibTreatmentHCC")}
          />
          <FormControlLabel
            value="alternativeTherapyHCC"
            control={<Radio
              checked={CabometyxValues.alternativeTherapyHCC}
              data-cy="alternativeTherapyHCC" />}
            label={t("cabometyx.alternativeTherapyHCC")}
          />
        </RadioGroup>
        <TextField
          hidden={(
            true
          )}
          style={{width: "100%"}}
          label={t("cabometyx.detailsTherapyTextHCC")}
          InputLabelProps={{shrink: true}}
          value={CabometyxValues.detailsTherapyTextHCC }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeCabometyxValue(
                {
                  detailsTherapyTextHCC: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
      </div>


      {
        /*
        // fourth option list DTC = true
        CabometyxValues.monotherapyAfterProgressionAgainstVEGFDTC,
        CabometyxValues.alternativeTherapyTextDTC,
        CabometyxValues.alternativeTherapyDTC,
        */
      }
      <div
        hidden={
          !(CabometyxValues.advancedMetastaticDifferentiatedThyroidCarcinomaTreatmentDTC === true)}>

        <FormLabel>{t("cabometyx.dtc")}</FormLabel>
        <RadioGroup
          onChange={(event, value) => {
            if (value === "monotherapyAfterProgressionAgainstVEGFDTC") {
              dispatch(onChangeCabometyxValue({formData: {monotherapyAfterProgressionAgainstVEGFDTC: true}}));
            } else if (value === "alternativeTherapyDTC") {
              dispatch(onChangeCabometyxValue({formData: {alternativeTherapyDTC: true}}));
            }
          }}>
          <FormControlLabel
            value="monotherapyAfterProgressionAgainstVEGFDTC"
            control={<Radio
              checked={CabometyxValues.monotherapyAfterProgressionAgainstVEGFDTC}
              data-cy="monotherapyAfterProgressionAgainstVEGFDTC" />}
            label={t("cabometyx.monotherapyAfterProgressionAgainstVEGFDTC")}
          />
          <FormControlLabel
            value="alternativeTherapyDTC"
            control={<Radio
              checked={CabometyxValues.alternativeTherapyDTC}
              data-cy="alternativeTherapyDTC" />}
            label={t("cabometyx.alternativeTherapyDTC")}
          />
        </RadioGroup>
        <Typography>
          {CabometyxValues.variant === "6" && t("cabometyx.alternativeTherapyTextDTC")}
        </Typography>
        <Box sx={{m: 1}}/>
        <TextField
          hidden={
            !(CabometyxValues.variant === "6")
          }
          style={{width: "100%"}}
          label={t("-")}
          InputLabelProps={{shrink: true}}
          value={CabometyxValues.alternativeTherapyTextDTC }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeCabometyxValue(
                {
                  alternativeTherapyTextDTC: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
      </div>

      <div
        hidden={
          !(
            CabometyxValues.default === true ||
            CabometyxValues.alternativeTherapyRCC === true ||
            CabometyxValues.alternativeTherapyHCC === true ||
            CabometyxValues.alternativeTherapyDTC === true ||
            CabometyxValues.monotherapyAfterPriorSorafenibTreatmentHCC === true
          )}>

        <Box sx={{m: 1}}/>
        <Typography hidden={!(CabometyxValues.variant === "1")}>{t("cabometyx.details.1")}</Typography>
        <Typography hidden={!(CabometyxValues.variant === "2")}>{t("cabometyx.details.2")}</Typography>
        <Typography hidden={!(CabometyxValues.variant === "3")}>{t("cabometyx.details.3")}</Typography>
        <Typography hidden={!(CabometyxValues.variant === "4")}>{t("cabometyx.details.4")}</Typography>
        <Typography hidden={!(CabometyxValues.variant === "5")}>{t("cabometyx.details.5")}</Typography>
        <Typography hidden={!(CabometyxValues.variant === "6")}>{t("cabometyx.details.6")}</Typography>
        <Typography hidden={!(CabometyxValues.variant === "7")}>{t("cabometyx.details.7")}</Typography>
        <Typography hidden={!(CabometyxValues.variant === "")}>{t("cabometyx.details.other")}</Typography>

        <Box sx={{m: 1}}/>
        <TextField
          style={{width: "100%"}}
          InputLabelProps={{shrink: true}}
          data-cy="preTreatment"
          label={t("cabometyx.defaultForm.preTreatment")}
          value={CabometyxValues.defaultForm?.preTreatment}
          multiline
          maxRows={4}
          minRows={4}
          variant="outlined"
          onChange = {(event ) =>
            dispatch(onChangeCabometyxValue(
                {
                  preTreatment: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          hidden={CabometyxValues.variant === "4"}
          multiline
          style={{width: "100%"}}
          label={t("cabometyx.defaultForm.patientCondition")}
          InputLabelProps={{shrink: true}}
          value={CabometyxValues.defaultForm?.patientCondition }
          variant="outlined"
          maxRows={4}
          minRows={4}
          onChange = {(event ) =>
            dispatch(onChangeCabometyxValue(
                {
                  patientCondition: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />

        <Box sx={{m: 1}}/>
        <TextField
          hidden={CabometyxValues.variant === "4"}

          style={{width: "100%"}}
          label={t("cabometyx.defaultForm.reasonsAlternativeTherapiesFailed")}
          InputLabelProps={{shrink: true}}
          value={CabometyxValues.defaultForm?.reasonsAlternativeTherapiesFailed }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeCabometyxValue(
                {
                  reasonsAlternativeTherapiesFailed: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />
        <Box sx={{m: 1}}/>
        <TextField
          hidden={true}
          style={{width: "100%"}}
          label={t("cabometyx.defaultForm.prognosis")}
          InputLabelProps={{shrink: true}}
          value={CabometyxValues.defaultForm?.prognosis }
          variant="outlined"
          maxRows={4}
          minRows={4}
          multiline
          onChange = {(event ) =>
            dispatch(onChangeCabometyxValue(
                {
                  prognosis: (event.target as HTMLInputElement).value,
                }),
            )
          }
        />

      </div>
      <Grid item xs={12}
        hidden={
          CabometyxValues.variant === "3" ||
          CabometyxValues.variant === "5" ||
          CabometyxValues.variant === "7" ||
          CabometyxValues.variant === ""
        }
      >
        <Box sx={{m: 2}}/>
        <Stack
          direction="row"
          alignItems="left"
          gap={1}
        >
          <WarningIcon color={"primary"}
          />
          <Typography>{t("cabometyx.info.ipson")}</Typography>

        </Stack>
      </Grid>


    </Grid>
    <Grid item xs={12}>
      {
        CabometyxValues.variant !=="" && CabometyxValues.variant !=="  " && CabometyxValues.default === false ?
          <FormLabel >{t("variante")}:&nbsp;{CabometyxValues.variant}&nbsp;</FormLabel> :
        <></>
      }
    </Grid>

  </>;
};


const mapStateToProps=(state) => {
  return {
  };
};

const mapDispatchToProps=(dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CabometyxForm);


