import React from "react";
import {Avatar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  connect,
  useDispatch,
} from "react-redux";
import {
  RemoveCircle,
  ChevronRight,
  BsFillChatSquareDotsFill,
} from "components/icons";
import makeStyles from "@mui/styles/makeStyles";
import {useHistory} from "react-router-dom";
import {
  formatNotificationAvatar,
  formatTimeDifference,
} from "components/formatting.js";
import {
  setHistoryAsRead,
} from "store/middleware/requestListThunk";

import {useTranslation} from "react-i18next";
import {onSearchChange} from "store/features/requestListSlice";
import {sendSetReminder} from "store/middleware/requestListThunk";
import {UseAppSelector} from "store/hooks";

const NotificationDialog=(props) => {
  const {t}=useTranslation();
  const notificationList=UseAppSelector((state) => state.requestList.notificationList);

  const dispatch=useDispatch();
  const history=useHistory();

  const useStyles=makeStyles((theme) => ({
    cardIcon: {
      padding: "15px",
      backgroundColor: "green",
      borderRadius: "7px",
      marginTop: "-30px",
      marginRight: "15px",
      float: "left",
      boxShadow: "4px 8px 20px 0 rgb(0 0 0 / 14%), 7px 8px 11px 0px rgb(144 145 144 / 40%)",
    },
    icon: {
      fontSize: 40,
      color: "white",
    },
    iconGroup: {
      position: "absolute",
    },
    listItem: {
      cursor: "pointer",
    },
    bold: {
      fontWeight: 600,
    },
  }));
  const classes=useStyles();
  const expandRow = (requestId) =>{
    props.onSearchChange(requestId.toString());
    if (document.getElementById(requestId)) {
      dispatch(sendSetReminder(requestId, null));
    }
  };

  const navToRequestList = (callback) => {
    setTimeout(()=>{
      history.push("/requests");
      callback();
    }, 1000);
  };


  return (
    <Drawer anchor="right" BackdropProps={{invisible: true}} open={props.isOpen} onClose={props.onClose}>
      <IconButton onClick={props.onClose} size="large">
        {props.isRemoving&&<CircularProgress color="inherit" />}
        <ChevronRight />
      </IconButton>
      <Divider />

      {props.unreadMessages>0&&<List>
        <ListItem className={classes.listItem} onClick={ ()=>history.push("/communication")}>
          <ListItemAvatar>
            <Avatar>
              <BsFillChatSquareDotsFill color="#63c2de"/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography component={"div"} display="inline" color=" #2f353a">{t("unread.messages")}: {props.unreadMessages} </Typography>}/>
        </ListItem>
        <Divider variant="inset"/>
      </List>
      }
      {
        !props.isLoadingNotificationHistory ? (
        <List subheader={<Typography component={"div"} className="pl-3">{t("notification.title")}:</Typography>} className="pt-2">
          {notificationList.map((notification, index) => {
            return (
              <div key={index}>
                <ListItem className={classes.listItem} onClick={() => {
                  notification.event_data==="STUDIES_RECEIVED"?
                    (history.push(`/edit_request/${notification.request_id}`)):
                    notification.event_id=="REMINDER_SET" || notification.event_id=="INSURANCE_RESPONSE_RECEIVED"? (history.location.pathname === "/requests" ?
                    expandRow(notification.request_id) :
                    navToRequestList(()=>expandRow(notification.request_id))):
                    (history.push("/substitution"));
                }}>
                  <ListItemAvatar>
                    <Avatar>
                      {formatNotificationAvatar(notification.event_id)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={notification.event_id==="SUBTITUTE_ADDED_BY"?
                      (<><Typography component={"div"} className={classes.bold} display="inline" color="textSecondary">{notification.event_data} </Typography>
                        <Typography component={"div"} display="inline">{t("notification.substitute.added")}</Typography>
                      </>):
                      (notification.event_id==="SUBTITUTE_REMOVED_BY"?
                        (<><Typography component={"div"} className={classes.bold} display="inline" color="textSecondary">{notification.event_data} </Typography>
                          <Typography component={"div"} display="inline">{t("notification.substitute.removed")}</Typography>
                        </>):
                        notification.event_id==="REMINDER_SET"?
                        (<><Typography component={"div"} className={classes.bold} display="inline" color="textSecondary">{notification.event_data} </Typography>
                          <Typography component={"div"} display="inline">{t("notification.reminder.set")}</Typography>
                        </>):
                        notification.event_id==="INSURANCE_RESPONSE_RECEIVED"?
                        (<><Typography component={"div"} className={classes.bold} display="inline" color="textSecondary">{t(`${notification.event_data}`)} </Typography>
                          <Typography component={"div"} display="inline">{t("notification.insurance.response")}</Typography>
                        </>):
                        (<><Typography component={"div"} display="inline">{t("notification.studies.added")} </Typography>
                          <Typography component={"div"} className={classes.bold} color="textSecondary" display="inline">{notification.request_id}</Typography>
                        </>))}
                    secondary={formatTimeDifference(t, notification.timestamp)}
                  />
                  <ListItemSecondaryAction>
                    {!props.isRemoving&&<IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        props.onRemoveNotification(notification.request_history_id);
                      }}
                      size="large">

                      <RemoveCircle />
                    </IconButton>}
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>
            );
          })}
        </List>
        ):(
        <Box sx={{display: "flex"}}>
          <CircularProgress />
        </Box>
        )}
    </Drawer>
  );
};
const mapStateToProps = (state) => {
  return {
    ...state,
    // isRemoving: true,
    isRemoving: state.loading.notificationDismiss,
    isLoadingNotificationHistory: state.loading.isLoadingObjects?.notificationHistory,
  };
};

const mapDispatchToProps = (dispatch)=>{
  return {
    onSearchChange: (value) => dispatch(onSearchChange({value: value})),
    onRemoveNotification: (requestHistoryId) => dispatch(setHistoryAsRead(requestHistoryId)),
  };
};

export default (connect(mapStateToProps, mapDispatchToProps)(NotificationDialog));
