import {isNullOrUndefined} from "@microsoft/applicationinsights-core-js";
import {createSlice} from "@reduxjs/toolkit";
import {trackError} from "AppInsights";

const initialState = {
  "needsValidation": false,
  "activeTab": "home",
  "localFiles": [],
  "downloadedFiles": [],
  "studiesStatus": false,
  "studiesFiles": [],
  "isLoading": false,
  "additionalDrugInfo": [],
  "kogu_type": "med",
  "lang": "De",
  "screenshotData": {},
  "insuranceCompanyData": [],
  "filesMarkedForRemoval": [],
  "studiesMarkedForRemoval": [],
  "pubmedStudy": {},
  "patientDiary": {},
};

const FormReducerSlice = createSlice({
  name: "SingleRequestReducer",
  initialState: initialState,
  reducers: {
    markStudiesForRemoval: (state, {payload})=>({
      ...state,
      studiesMarkedForRemoval: [...state.studiesMarkedForRemoval, payload],
    }),
    markFilesForRemoval: (state, {payload})=>({
      ...state,
      filesMarkedForRemoval: [...state.filesMarkedForRemoval, payload],
    }),
    onLangChange(state, action) {
      return {
        ...state,
        lang: action.payload,
      };
    },
    onSelectClinicObject(state, {payload}) {
      return {
        ...state,
        requiredRehaData: {
          ...state.requiredRehaData,
          clinic: payload.CENTERNAME,
          city: payload.CITY,
          email: payload.MAIL,
          phone_no: payload.PHONE,
          postalcode: payload.PLZ,
          street_no: payload.STREET,
          country: "CH",
        },
      };
    },
    onSelectMigelObject(state, {payload}) {
      return {
        ...state,
        requiredMigelData: {
          ...state.requiredMigelData,
          limitatio_details: payload.limitations,
          specialist_details: payload.specialistinformation,
          required_migel_name: payload.productname,
          pharma_email: payload.pharmaCompanyMailAdress,
          pharma_company_name: payload.prtnoPharmaCompany,
          optionInMigel: true,
        },
      };
    },


    onKoguTypeChanged(state, action) {
      return {
        ...state,
        kogu_type: action.payload,
      };
    },
    onReceivedEditData(state, {payload}) {
      return {
        ...state,
        caseId: payload.case_id,
        kogu_type: payload.kogu_type,
        patientData: {
          patient_surename: payload.patient_surename,
          patient_lastname: payload.patient_lastname,
          patient_date_of_birth: payload.patient_date_of_birth,
          patient_postalcode: payload.patient_postalcode,
          patient_street: payload.patient_street,
          patient_city: payload.patient_city,
          patient_country: payload.patient_country,
          patient_insurance_company: payload.patient_insurance_company,
          patient_insurance_company_gln: payload.patient_insurance_company_gln,
          patient_insurance_no: payload.patient_insurance_no,
          patient_gender: payload.patient_gender,
          patient_phone_no: payload.patient_phone_no,
          patient_agreement: payload.patient_agreement,
          patient_email_addr: payload.patient_email_addr,
        },
        doctorData: {
          doctor_title: payload.doctor_title,
          doctor_lastname: payload.doctor_lastname,
          doctor_surename: payload.doctor_surename,
          doctor_specialty: payload.doctor_specialty,
          doctor_center_name: payload.doctor_center_name,
          doctor_postalcode: payload.doctor_postalcode,
          doctor_street: payload.doctor_street,
          doctor_city: payload.doctor_city,
          doctor_country: payload.doctor_country,
          doctor_email_addr: payload.doctor_email_addr,
          doctor_phone_no: payload.doctor_phone_no,
          doctor_fax_no: payload.doctor_fax_no,
        },
        editData: {
          ...payload,
        },
        localFiles: [],
        downloadedFiles: [],
        filesMarkedForRemoval: [],
      };
    },
    cleanData(state, {payload}) {
      return {
        ...state,
        caseId: "",
        pubmedStudy: {},
        patientData: {
          patient_surename: "",
          patient_lastname: "",
          patient_date_of_birth: "",
          patient_postalcode: "",
          patient_street: "",
          patient_city: "",
          patient_country: "CH",
          patient_insurance_company: "",
          patient_insurance_company_gln: "",
          patient_insurance_no: "",
          patient_gender: "",
          patient_phone_no: "",
          patient_agreement: "",
          patient_email_addr: "",
        },
        doctorData: {
          doctor_title: "",
          doctor_lastname: "",
          doctor_surename: "",
          doctor_specialty: "",
          doctor_center_name: "",
          doctor_postalcode: "",
          doctor_street: "",
          doctor_city: "",
          doctor_country: "",
          doctor_email_addr: "",
          doctor_phone_no: "",
          doctor_fax_no: "",
        },
        editData: {},
        patientDiary: {},
        localFiles: [],
        downloadedFiles: [],
        filesMarkedForRemoval: [],
        studiesStatus: false,
        studiesFiles: [],
        screenshotData: {},
        needsValidation: "",
        requiredRehaData: {},
        kogu_type: payload?.kogu_type ?? "med",
        requiredDrugData: {},
        additionalDrugInfo: [],
        formComputedData: {},
      };
    },
    cleanDocumentState(state, {payload}) {
      return {
        ...state,
        localFiles: [],
        studiesStatus: false,
        studiesFiles: [],
        downloadedFiles: [],
        filesMarkedForRemoval: [],
        pubmedStudy: {},
      };
    },

    cleanRequiredDrugData(state, {payload}) {
      return {
        ...state,
        requiredDrugData: {},
      };
    },
    onReceivedPatientData(state, {payload}) {
      const patient_history = {};
      payload?.forEach((element) => {
        if (element.field_name === "patient_city") {
          patient_history.patient_city = element.value;
        }
        if (element.field_name === "patient_lastname") {
          patient_history.patient_lastname = element.value;
        }
        if (element.field_name === "patient_surename") {
          patient_history.patient_surename = element.value;
        }
        if (element.field_name === "patient_postalcode") {
          patient_history.patient_postalcode = element.value;
        }
        if (element.field_name === "patient_street") {
          patient_history.patient_street = element.value;
        }
      });
      return {
        ...state,
        isError: false,
        patientDataHistory: patient_history,
      };
    },

    onValidate(state, {payload}) {
      return {
        ...state,
        needsValidation: {
          ...state.needsValidation,
          ...payload,
        },
      };
    },

    onReceivedDoctorDataPrefill(state, {payload}) {
      if (!Array.isArray(payload)) {
        return {
          ...state,
          doctorData: {
            "doctor_title": "",
            "doctor_lastname": "",
            "doctor_surename": "",
            "doctor_specialty": "",
            "doctor_center_name": "",
            "doctor_postalcode": "",
            "doctor_street": "",
            "doctor_city": "",
            "doctor_country": "",
            "doctor_email_addr": "",
            "doctor_phone_no": "",
            "doctor_fax_no": "",
          },
        };
      }
      let email = payload[0]?.email;
      if (Array.isArray(payload[0]?.emails)) {
        email = payload[0].emails[0];
      }
      return {
        ...state,
        ...(!(state.editData?.request_id) && {
          doctorData: {
            "doctor_title": payload[0].extension_title,
            "doctor_lastname": payload[0].family_name,
            "doctor_surename": payload[0].givenName,
            "doctor_specialty": payload[0].extension_Profession,
            "doctor_center_name": payload[0].extension_center_name,
            "doctor_postalcode": payload[0].postalCode,
            "doctor_street": payload[0].streetAddress,
            "doctor_city": payload[0].city,
            "doctor_country": payload[0].country,
            "doctor_email_addr": email,
            "doctor_phone_no": payload[0].extension_phone_number,
            "doctor_fax_no": payload[0].extension_fax_number,
          },
        }),
      };
    },

    onReceivePatientDiary(state, {payload}) {
      return {
        ...state,
        patientDiary: payload.patientDiary,
      };
    },

    onSelectDoctorObject(state, {payload}) {
      let email = "";
      if (Array.isArray(payload.emails)) {
        email = payload.emails[0];
      } else {
        email = payload.email;
      }
      return {
        ...state,
        doctorData: {
          "doctor_title": payload.extension_title,
          "doctor_lastname": payload.family_name,
          "doctor_surename": payload.givenName,
          "doctor_specialty": payload.extension_Profession,
          "doctor_center_name": payload.extension_center_name,
          "doctor_postalcode": payload.postalCode,
          "doctor_street": payload.streetAddress,
          "doctor_city": payload.city,
          "doctor_country": payload.country,
          "doctor_email_addr": email,
          "doctor_phone_no": payload.extension_phone_number,
          "doctor_fax_no": payload.extension_fax_number,
        },
      };
    },
    uploadLocalFilesAction(state, action) {
      const files = action.payload.type ?
        action.payload.files :
        state.localFiles.concat(action.payload.files);
      return {
        ...state,
        localFiles: files,
      };
    },

    removeLocalFilesAction(state, action) {
      return {
        ...state,
        localFiles: action.payload,
      };
    },

    addMetaDataTouploadStepperFiles(state, action) {
      const files = state.localFiles;
      const metadataarray = action.payload;
      files.forEach( (file) => {
        metadataarray.forEach( (metadata) => {
          if (file.name== metadata?.filename) {
            file.doi = metadata?.doi;
            file.nct = metadata?.nct;
            file.pmcid = metadata?.pmcid;
            file.pmid = metadata?.pmid;
            file.abstract = metadata?.abstract;
            file.link = metadata?.link;
            file.pubtype = metadata?.pubtype;
            file.title = metadata?.title;
            file.authors = metadata?.authors;
          }
        });
      });
      return {
        ...state,
        localFiles: files,
      };
    },

    updateLocalFile(state, action) {
      const files = [...state.localFiles];
      const {idx, remarks, uploadtype} = action.payload;
      if (!isNullOrUndefined(remarks)) {
        files[idx].remarks = remarks;
      }
      if (uploadtype) {
        files[idx].uploadtype = uploadtype;
      }
      return {
        ...state,
        localFiles: files,
      };
    },

    updateDownloadedFile(state, action) {
      const files = [...state.downloadedFiles];
      const {idx, remarks, uploadtype} = action.payload;
      if (!isNullOrUndefined(remarks)) {
        files[idx] = {...files[idx]};
        files[idx].remarks = remarks;
      }
      if (uploadtype) {
        files[idx] = {...files[idx]};
        files[idx].uploadtype = uploadtype;
      }
      return {
        ...state,
        downloadedFiles: [...files],
      };
    },

    errorServiceException(state, action) {
      trackError(action.error);
      return {
        ...state,
        isLoading: action.payload.isLoading,
        isLoadingDocument: {
          ...state.isLoadingDocument,
          ...(action.payload?.documentId ? {
            [action.payload.documentId]: false,
          } : {}),
        },
        isError: true,
      };
    },
    setDownloadFiles(state, action) {
      const downloadedFiles = action.payload.type ?
        state.downloadedFiles.filter(
            (file) => file.fileURL !== action.payload.file.fileURL) :
        state.downloadedFiles.concat(action.payload.files);
      return {
        ...state,
        downloadedFiles: downloadedFiles,
      };
    },
    showBanner(state, action) {
      return {
        ...state,
        showBanner: action.payload,
      };
    },


    onSelectPatientObject(state, {payload}) {
      return {
        ...state,
        patientData: {
          ...state.patientData,
          patient_city: payload?.city,
          patient_country: payload?.country?.toUpperCase(),
          patient_date_of_birth: payload?.date_of_birth,
          patient_email_addr: payload?.email_addr,
          patient_gender: payload?.gender,
          patient_insurance_company: payload?.insurance_company,
          patient_insurance_company_gln: payload?.insurance_company_gln,
          patient_insurance_no: payload?.insurance_no,
          patient_lastname: payload?.name,
          patient_phone_no: payload?.phone,
          patient_postalcode: payload?.zip,
          patient_street: payload?.street,
          patient_surename: payload?.firstname,
        },
      };
    },

    onAddOrRemoveStudy(state, {payload}) {
      return {
        ...state,
        pubmedStudy: state?.pubmedStudy?.pmid===payload?.pmid?{}:payload,
      };
    },

    onAddStudy(state, {payload}) {
      return {
        ...state,
        pubmedStudy: payload,
      };
    },

    onRemoveStudy(state) {
      return {
        ...state,
        pubmedStudy: null,
      };
    },

    onReceivedScreenshotData(state, {payload}) {
      if (payload.patient_gender && payload.patient_gender.startsWith("m")) {
        payload.patient_gender = 0;
      }
      if (payload.patient_date_of_birth) {
        // the date field needs:    2021-11-03 YYYY-MM-DD
        // we get from the service: 21-10-1973 DD-MM-YYYY
        const [day, month, year] = payload.patient_date_of_birth.split(".");
        payload.patient_date_of_birth = year + "-" + month + "-" + day;
      }
      console.debug("onReceivedScreenshotData", payload);
      return {
        ...state,
        patientData: {
          ...state.patientData,
          ...payload,
        },
      };
    },

  },
});

export const {
  cleanDocumentState,
  onLangChange,
  onKoguTypeChanged,
  onRequiredMigelLivechange,
  onSelectDoctorObject,
  onSelectMigelObject,
  onSelectICD10Object,
  onReceivedEditData,
  onReceivedPatientData,
  onReceivedInsuranceCompanyData,
  onReceivedBagData,
  onReceivedICD10Data,
  onReceivedMigelData,
  onReceivedRehaData,
  onReceivedDoctorData,
  onReceivedBagDataCombined,
  onReceivedScreenshotData,
  onValidate,
  uploadLocalFilesAction,
  addMetaDataTouploadStepperFiles,
  updateLocalFile,
  updateDownloadedFile,
  errorServiceException,
  setDownloadFiles,
  showBanner,
  toggleTherpayDurartion,
  toggleSpecialistInfo,
  onSelectPatientObject,
  onSelectClinicObject,
  onReceivedDoctorDataPrefill,
  cleanData,
  onAddOrRemoveStudy,
  onAddStudy,
  onRemoveStudy,
  markFilesForRemoval,
  markStudiesForRemoval,
  removeLocalFilesAction,
  onReceivePatientDiary,
  cleanRequiredDrugData,
} = FormReducerSlice.actions;


export const state_singleRequest_requiredDrugData=(state) =>state.singleRequest?.requiredDrugData;
export const selectPatientDiary=(state) =>state.singleRequest?.patientDiary;
export const selectPatientData=(state) =>state.singleRequest?.patientData;

export const selectPubmenStudy=(state) =>state.singleRequest?.pubmedStudy;
export const selectPatientDocuments=(state) =>state.singleRequest?.localFiles;
export const selectDownloadedFiles=(state) =>state.singleRequest?.downloadedFiles;


export default FormReducerSlice.reducer;
